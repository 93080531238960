.article {
  .statistic {
    position: relative;
    z-index: 1;

    &__illustration {
      position: absolute;
      z-index: -1;

      &:before {
        display: block;
        content: '';
        background-size: contain !important;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
    }
  }

  &--horizontal {
    margin: -23px 290px 0 180px;
    position: relative;

    .statistic {
      &__content-wrapper {
        margin-top: 32px;
      }

      &__illustration {
        top: -37vh;
        right: -26vh;
        z-index: -1;

        &:before {
          background-image: url(/assets/images/illustrations/passion.png);
          width: 60vh;
          height: 60vh;

          @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url(/assets/images/illustrations/passion@x2.png);
            background-size: contain;
            width: 60vh;
            height: 60vh;
          }
        }
      }
    }

    @include mq($until: m) {
      margin-right: 0;

      .statistic {
        &__illustration {
          top: -20vw;
          right: 0;

          &:before {
            width: 30vw;
            height: 30vw;

            @media (-webkit-min-device-pixel-ratio: 2),
              (min-resolution: 192dpi) {
              width: 30vw;
              height: 30vw;
            }
          }
        }
      }
    }
  }

  &--global {
    margin: -23px 230px 0 0;
    position: relative;

    .statistic {
      &__content-wrapper {
        margin-top: 32px;
      }

      &__visual-wrapper {
        width: 490px;
        height: 361px;
      }

      &__illustration {
        top: -210px;
        right: -150px;
        z-index: 1;
        &:before {
          background-image: url(/assets/images/illustrations/blackberry.png);
          width: 377px;
          height: 264px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;

          @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url(/assets/images/illustrations/blackberry@x2.png);
            background-size: contain;
            width: 377px;
            height: 264px;
          }
        }
      }

      @include mq($until: m) {
        &__illustration {
          display: none;
        }
      }
    }
  }

  &--vertical {
    margin: -185px 290px 0 0;
    position: relative;

    .statistic {
      &__content-wrapper {
        margin-top: 137px;
        max-width: 382px;
      }

      &__visual-wrapper {
        width: 411px;
        height: 512px;
      }

      &__illustration {
        bottom: -30vh;
        left: 12vh;
        z-index: 1;

        &:before {
          background-image: url(/assets/images/illustrations/eggplant.png);
          width: 60vh;
          height: 60vh;
          z-index: 1;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;

          @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url(/assets/images/illustrations/eggplant@x2.png);
            background-size: contain;
            width: 60vh;
            height: 60vh;
          }
        }
      }
    }

    @include mq($until: m) {
      margin-right: 0;

      .statistic {
        &__content-wrapper {
          margin-top: 0px;
          max-width: 229px;
        }

        &__visual-wrapper {
          width: 229px;
          height: 144px;
        }

        &__illustration {
          top: -17vw;
          left: -6vw;

          &:before {
            width: 40vw;
            height: 40vw;
            transform: rotate(90deg);

            @media (-webkit-min-device-pixel-ratio: 2),
              (min-resolution: 192dpi) {
              width: 40vw;
              height: 40vw;
            }
          }
        }
      }
    }
  }

  &--worldwide {
    margin: 0 300px 0 180px;
    position: relative;

    .statistic {
      &__content-wrapper {
        margin-top: 126px;
      }

      &__container {
        margin-left: 0;
      }

      &__title {
        margin-bottom: 40px;
      }

      &__visual-wrapper {
        width: 349px;
        height: 524px;
      }

      &__illustration {
        top: -35vh;
        left: 35vh;
        z-index: 1;

        &:before {
          background-image: url(/assets/images/illustrations/beans.png);
          width: 65vh;
          height: 65vh;

          @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url(/assets/images/illustrations/beans@x2.png);
            background-size: contain;
            width: 65vh;
            height: 65vh;
          }
        }
      }
    }

    @include mq($until: m) {
      .statistic {
        flex-direction: column-reverse;

        &__illustration {
          left: unset;
          top: -11vw;
          right: -13vw;

          &:before {
            width: 60vw;
            height: 60vw;

            @media (-webkit-min-device-pixel-ratio: 2),
              (min-resolution: 192dpi) {
              width: 60vw;
              height: 60vw;
            }
          }
        }
      }
    }

    @include mq($until: s) {
      .statistic {
        &__illustration {
          bottom: -40px;
        }
      }
    }
  }

  &--mission {
    .statistic {
      &__container {
        margin-right: 45px;
        margin-left: 0;
      }

      &__visual-wrapper {
        width: 409px;
        height: 453px;
      }
    }
  }

  &--testimonial {
    position: relative;

    .testimonial {
      &__illustration {
        position: absolute;
        top: -110px;
        left: -10px;
        z-index: 1;
        &:before {
          display: block;
          content: '';
          background-image: url(/assets/images/illustrations/corn.png);
          width: 424px;
          height: 353px;

          @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url(/assets/images/illustrations/corn@x2.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            width: 424px;
            height: 353px;
          }
        }
      }
    }

    @include mq($until: m) {
      .testimonial {
        &__illustration {
          top: 15vw;
          left: auto;
          right: -10vw;
          z-index: 0;

          &:before {
            width: 40vw;
            height: 40vw;
            transform: rotate(-90deg);

            @media (-webkit-min-device-pixel-ratio: 2),
              (min-resolution: 192dpi) {
              width: 40vw;
              height: 40vw;
            }
          }
        }
      }
    }
  }

  &--testimonial2 {
    position: relative;
    .testimonial {
      &__content {
        width: 253px;
        margin-top: 62px;
        margin-right: 58px;
      }

      &__visual-wrapper {
        width: 208px;
        height: 260px;
      }

      &__illustration {
        position: absolute;
        bottom: -20vh;
        left: unset;
        z-index: 1;
        top: unset;
        right: -5vh;
        z-index: 1;

        &:before {
          display: block;
          content: '';
          background-image: url(/assets/images/illustrations/sweetp.png);
          width: 50vh;
          height: 50vh;
          background-size: contain;
          background-position: 50% 50%;
          background-repeat: no-repeat;

          @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url(/assets/images/illustrations/sweetp@x2.png);
            background-size: contain;
            width: 50vh;
            height: 50vh;
          }
        }
      }
    }
  }

  &--discover {
    margin: 0 0 0 200px;

    .statistic {
      &__content-wrapper {
        max-width: 352px;
      }

      &__container {
        margin-left: 0;
      }
    }
  }

  &--image {
    height: 100%;
  }

  @include mq($until: m) {
    &--horizontal,
    &--vertical,
    &--testimonial,
    &--testimonial2,
    &--discover,
    &--champions,
    &--global,
    &--worldwide,
    &--mission {
      margin: 0 100px;
    }

    &--horizontal {
      padding-top: 160px;

      .statistic__visual-wrapper {
        display: none;
      }

      .statistic {
        padding-bottom: 100px;

        &__content-wrapper {
          margin-top: 0;
        }
      }
    }

    &--vertical {
      .statistic {
        position: relative;

        &__content-wrapper {
          margin-top: 0;
          max-width: 100%;
          text-align: right;
          margin-bottom: 0;
        }

        &__visual-wrapper {
          width: 300px;
          height: 340px;
          position: absolute !important;
          left: 32px;
          bottom: 0;
        }

        &__container {
          float: right;
        }
      }

      .statistic__visual-wrapper {
        display: none;
      }
    }

    &--global {
      .statistic {
        margin-bottom: 100px;

        &__content-wrapper {
          margin-top: 0;
        }

        .statistic__visual-wrapper {
          width: unset;
          height: 144px;
        }

        .visual {
          left: 0;
          right: 0;
          width: 100%;
        }
      }

      &:before {
        display: none;
      }
    }

    &--worldwide {
      .statistic {
        margin-bottom: 100px;

        &__content-wrapper {
          margin-top: 0;
        }

        &__container {
          margin-left: 0;
        }

        &__title {
          margin-bottom: 40px;
        }

        &__visual-wrapper {
          margin-top: 40px;
          margin-bottom: 0;
          width: 100%;
          left: 0;
          right: 0;
          height: 211px;
        }
      }
    }

    &--mission {
      .statistic {
        flex-direction: column-reverse;

        &__container {
          margin-right: 0;
          margin-left: 0;
          width: 100%;
        }

        &__visual-wrapper {
          margin-top: 40px;
          margin-bottom: 100px;
          width: 100%;
          right: 0;
          left: 0;
          height: 300px;

          .visual {
            right: 0;
            left: 0;
          }
        }
      }
    }

    &--testimonial2 {
      .testimonial {
        &__content {
          width: 100%;
          margin-top: 0;
          margin-right: 0;
        }

        &__visual-wrapper {
          position: relative;
          width: 270px;
          height: 311px;
        }

        &__illustration {
          top: -4vw;
          right: 0;

          &:before {
            width: 60vw;
            height: 60vw;

            @media (-webkit-min-device-pixel-ratio: 2),
              (min-resolution: 192dpi) {
              width: 60vw;
              height: 60vw;
            }
          }
        }
      }
    }

    &--discover {
      margin-bottom: 100px;

      .statistic {
        &__content-wrapper {
          max-width: none;
        }

        &__container {
          margin-left: 0;
          width: 100%;
        }
      }
    }

    &--image {
      height: 200px;
      margin-bottom: 100px;
    }
  }

  @include mq($until: s) {
    &--horizontal,
    &--vertical,
    &--testimonial,
    &--testimonial2,
    &--discover,
    &--champions,
    &--global,
    &--worldwide,
    &--mission {
      margin-left: 0;
      margin-right: 0;
    }

    &--horizontal {
      .statistic {
        padding-bottom: 100px;
      }
    }

    &--vertical {
      .statistic {
        padding-bottom: 0;

        &__visual-wrapper {
          width: 170px;
          height: 211px;
        }
      }
    }

    &--mission {
      .statistic {
        &__visual-wrapper {
          width: calc(100% - 64px);
          height: 211px;
          right: 32px;
          left: 32px;
        }

        &__content-wrapper {
          width: 100%;
          // padding-bottom: 360px;
        }
      }
    }

    &--worldwide {
      .statistic {
        &__visual-wrapper {
          width: calc(100% - 64px);
          left: 32px;
          right: 32px;
        }
      }
    }

    &--global {
      .statistic {
        .visual {
          left: 32px;
          right: 32px;
          width: calc(100% - 64px);
        }
      }
    }

    // &--testimonial2 {
    //     .testimonial {
    //         margin-top: 0;

    //         &__content {
    //             //width: 100%;
    //             margin-top: 0;
    //             margin-right: 0;
    //         }

    //         &__visual-wrapper {
    //             width: 170px;
    //             height: 211px;
    //         }
    //     }
    // }

    &--champions {
      margin-right: 0;
    }
  }
}

.article--mission {
  @include mq($from: m) {
    margin-right: 218px;
  }
}

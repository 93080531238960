.statistic {
  position: relative;
  display: flex;
  flex-direction: column;

  &__content-wrapper {
    //max-width: 100%;
    color: #d2d2d2;

    @include mq($from: xs) {
      max-width: 30rem;
      //margin-bottom: 6.5rem;
    }
  }

  &__number {
    font-family: Futura Bold;
    color: $c-yellow;
    position: relative; // Fix z-index issure on mobile
    margin-bottom: 40px;
    font-size: 9.1rem;
    line-height: 1;
    white-space: nowrap;

    @include mq($from: s) {
      margin-bottom: 2.4rem;
    }
  }

  &__container {
    margin-left: 30px;
  }

  &__title {
    font-family: Futura Bold;
    margin-bottom: 30px;
    font-size: 2.8rem;
    line-height: 1.25;
    color: $c-text-dark;
  }

  &__txt {
    font-family: Futura Book;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: .6px;
    color: $c-text-dark;
    margin-bottom: 40px;
  }

  &__links {
    margin-top: 55px;
  }

  &__link {
    font-family: Futura Book;
    font-size: 15px;
    line-height: 13px;
    color: $c-yellow;
    display: table;
    margin-bottom: 36px;
    position: relative;
    padding-bottom: 6px;

    &:after {
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        background-color: $c-yellow;
        position: absolute;
        bottom: 0;
        transform-origin: left;
        transition: transform 300ms $ease-out-quad;
    }

    &:last-child {
      margin-bottom: 62px;
    }

    &:hover,
    &:focus {
        &:after {
            transform: scaleX(0);
        }
    }
  }

  &__visual-wrapper {
    width: 475px;
    height: 348px;
    position: relative;

    .visual {
      right: 0;
      left: 0;
    }
  }

  @include mq($from: m) {
    flex-direction: row-reverse;
  }

  @include mq($until: m) {
    &__number {
      font-size: 52px;
      margin-bottom: 20px;
    }

    &__title {
      font-size: 24px;
    }

    &__content-wrapper {
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
      max-width: 100%;
    }

    &__container {
      margin-left: 0;
      width: 400px;
    }

    &__visual-wrapper {
      margin-bottom: 40px;
    }
  }

  @include mq($until: s) {
    &__content-wrapper {
      padding: 0 32px;
    }
  }

  @include mq($until: xs) {
    
    &__container {
      margin-left: 0;
      width: 229px;
    }

    &__content-wrapper {
      margin-bottom: 0;
    }
  }
}
.article--champions {
  height: 100%;
  padding: 100px 0;
}

.champions {
  position: relative;
  display: flex;
  flex-direction: column;
  //   padding-bottom: 265px;
  padding-right: 434px;
  margin: 0 322px 0 178px;
  height: 100%;

  &__content-wrapper {
    width: 390px;
    color: #d2d2d2;
  }

  &__number {
    font-family: Futura Bold;
    color: $c-yellow;
    position: relative; // Fix z-index issure on mobile
    margin-bottom: 40px;
    font-size: 9.1rem;
    line-height: 1;
    white-space: nowrap;

    @include mq($from: s) {
      margin-bottom: 2.4rem;
    }
  }

  &__container {
    margin-left: 23px;

    @include mq($until: m) {
      margin-bottom: 50px;
    }
  }

  &__title {
    font-family: Futura Bold;
    margin-bottom: 30px;
    font-size: 2.8rem;
    line-height: 1.25;
    mix-blend-mode: difference;
  }

  &__txt {
    font-family: Futura Book;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: $c-text-dark;
    margin-bottom: 40px;
  }

  .champions__list {
    display: flex;
    flex-wrap: wrap;

    @include mq($from: xs, $until: m) {
      margin-left: -25px;
      margin-right: -25px;
    }
  }

  .champion {
    width: 100%;

    @include mq($from: xs, $until: m) {
      width: 50%;
      padding-left: 25px;
      padding-right: 25px;
    }

    @include mq($until: m) {
      margin-bottom: 20px;

      .champions__visual-wrapper {
        width: 100%;
        aspect-ratio: 1/1;
      }
    }

    @include mq($from: m) {
      --w: 211px;
      --h: 211px;

      position: absolute;
      width: var(--w);
      letter-spacing: 0.06em;

      .champions__visual-wrapper {
        margin-bottom: 15px;
        position: relative;
        height: var(--h);
        width: 100%;
      }

      &:nth-child(1) {
        bottom: 0;
        left: 50px;
      }

      &:nth-child(2) {
        --w: 176px;
        --h: 156px;
        bottom: 150px;
        left: 350px;
      }

      &:nth-child(3) {
        --w: 177px;
        --h: 190px;
        bottom: 450px;
        left: 600px;
      }

      &:nth-child(4) {
        --w: 182px;
        --h: 162px;
        bottom: 25px;
        left: 650px;
      }
    }
  }

  .champion__title {
    font-size: 14px;
    line-height: 26px;
  }

  .champion__subtitle {
    color: #7d8093;
    font-size: 13px;
    line-height: 18px;
  }

  .visual {
    right: 0;
    left: 0;
  }

  @include mq($until: m) {
    padding: 0;
    margin: 0;

    &__content-wrapper {
      width: auto;
      margin-bottom: 0;
    }

    &__container {
      margin-left: 0;
      width: 400px;
    }

    &__number {
      font-size: 52px;
      margin-bottom: 20px;
    }

    &__title {
      font-size: 24px;
    }
  }

  @include mq($until: s) {
    padding: 0 32px 0;
  }

  @include mq($until: xs) {
    padding: 0 32px 250px;

    &__container {
      width: 310px;
    }
  }
}

.socials {
    display: flex;

    &__link {
        background-color: white;
        border: 1px solid $c-light-grey;
        width: 54px;
        height: 54px;
        border-radius: 100%;
        display: block;
        margin-right: 20px;
        position: relative;
        transition: background 300ms $ease-out-quad;

        &--linkedin {
            &:hover,
            &:focus {
                background-color: $c-linkedin;
            }
        }

        &--facebook {
            &:hover,
            &:focus {
                background-color: $c-facebook;
            }
        }

        &--twitter {
            &:hover,
            &:focus {
                background-color: $c-twitter;
            }
        }

        &:hover,
        &:focus {
            svg {
                fill: white;
            }
        }
    }

    &__svg {
        width: 22px;
        height: 22px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 15px;
        transition: fill 300ms $ease-out-quad;
    }
}
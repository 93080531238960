.progressBar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 3px;
    display: block;
    z-index: 99;

   &__colors {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to left, $c-orange, $c-darkorange, $c-purple, $c-darkblue, $c-blue, $c-green, $c-lemon);
   }

   &__white {
        width: 105%;
        height: 100%;
        position: absolute;
        top: 0;
        left: -5%;
        right: 0;
        z-index: 9;
        background: linear-gradient(to right, rgba(white,0) 0%, rgba(white,1) 5%);
   }
}
.video {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
    opacity: 0;
    transform: translateY(1px);
    visibility: hidden;
    transition: opacity 300ms 0ms $ease-out-quad,
                visibility 0ms 300ms $ease-out-quad;

    &--show {
        transform: none;
        opacity: 1;
        visibility: visible;
        transition: opacity 300ms 200ms $ease-out-quad;
    }

    &__video {
        width: 100vw;
        height: 100vh;
        position: fixed;
        display: block;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        object-fit: cover;
    }

    &__close {
        position: absolute;
        top: 51px;
        right: 60px;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        border: 1px solid white;
        background-color: transparent;
        cursor: pointer;
        z-index: 999;
        transition: transform 300ms $ease-out-quad;

        &:hover,
        &:focus {
            transform: rotate(90deg);
        }
    }

    &__svg {
        width: 14px;
        height: 14px;
        fill: white;
    }

    &__controls {
        position: absolute;
        bottom: 54px;
        right: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 64px 0 54px;
    }

    &__timeline {
        width: calc(100% - 220px);
        height: 3px;
        position: absolute;
        left: 100px;
        top: 10px;
        overflow: hidden;
        cursor: pointer;
    }

    &__back {
        height: 100%;
        width: 100%;
        background-color: rgba(white,.5);
        position: absolute;
    }

    &__progress {
        height: 100%;
        width: 100%;
        background: linear-gradient(to left, $c-orange, $c-darkorange, $c-purple, $c-darkblue, $c-blue, $c-green, $c-lemon);
        z-index: 2;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        transform-origin: left;
    }

    &__duration {
        position: absolute;
        right: 64px;
        top: -6px;
    }

    &__number {
        font-family: futura bold;
        font-size: 12px;
        line-height: 26px;
        letter-spacing: 2px;
        color: white;
    }

    &__btnplay,
    &__btnstop {
        border:none;
        background-color: transparent;
        cursor: pointer;
        position: absolute;
        left: 54px;
        padding: 5px;
        transition: opacity 300ms $ease-out-quad;

        &:after {
            display: block;
            content: '';
        }

        &:disabled {
            opacity: 0;
            visibility: hidden;
        }
    }

    &__btnplay {
        &:after {
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;   
            border-left: 10px solid white;
        }
    }

    &__btnstop {
        &:after {
            width: 9px;
            height: 11px;
            top:calc(50% - 6px);
            left:calc(50% - 5px);
            border-right:3px solid white;
            border-left: 3px solid white;
        }
    }

    @include mq($until: m) {
        height: 100vh;
        position: fixed;

        &__close {
            top: 20px;
            right: 20px;
        }

        &__timeline {
            width: calc(100% - 160px);
            left: 70px;
        }

        &__btnplay,
        &__btnstop {
            left: 32px;
        }

        &__duration {
            right: 32px;
            top: -3px
        }
    }
}
.post-preview {
    width: 239px;

    &__visual-wrapper {
        width: 100%;
        max-width: 100%;
        height: 20rem;
        margin-bottom: 14px;
        padding: 0;
    }

    &__date {
        font-family: Futura Book;
        margin-bottom: 6px;
        color: $c-gray-blue;
        font-size: 12px;
        line-height: 26px;
        letter-spacing: 3px;
    }

    &__title {
        font-family: Futura Bold;
        font-weight: bold;
        margin-bottom: 12px;
        font-size: 22px;
        line-height: 35px;
    }

    &__description {
        font-family: Futura Book;
        color: $c-text-dark;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: .6px;
        margin-bottom: 20px;
    }

    &__link {
        font-family: Futura Book;
        font-size: 15px;
        line-height: 13px;
        color: $c-yellow;
        display: table;
        position: relative;
        padding-bottom: 6px;

        &:after {
            display: block;
            content: '';
            width: 100%;
            height: 1px;
            background-color: $c-yellow;
            position: absolute;
            bottom: 0;
            transform-origin: left;
            transition: transform 300ms $ease-out-quad;
        }

        &:hover,
        &:focus {
            &:after {
                transform: scaleX(0);
            }
        }
    }

    &__content {
        @include mq($from: s) {
            max-width: 24rem;
        }
    }

    @include mq($until: m) {
        width: auto;

        &__title {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 11px;
        }

        &__description {
            font-size: 12px;
            line-height: 20px;
            margin-bottom: 15px;
        }

        .visual {
            right: 0 !important;
            left: 0 !important;
            transform: none !important;
        }

        &__visual-wrapper {
            height: 200px;
        }
    }

    @include mq($until: s) {

        &__visual-wrapper {
            height: 119px;
        }
    }
}
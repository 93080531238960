$action-content-width: 540px;

.action {
  padding: 0 167px 0 143px;

  &__title {
    font-family: Futura Bold;
    font-size: 48px;
    line-height: 38px;
    color: $c-text-dark;
    margin-bottom: 53px;
  }

  &__container {
    display: flex;
  }

  &__left {
    width: 368px;
    margin-right: 160px;
  }

  &__right {
    @include mq($from: s) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 80vh;

      @media (max-height: 850px) {
        $margin-right-offset: 50px;

        // Did not successfully managed to use flexbox here...
        // https://stackoverflow.com/questions/33891709/when-flexbox-items-wrap-in-column-mode-container-does-not-grow-its-width
        width: $action-content-width * 2; // Display actions on two columns
        margin-right: -$margin-right-offset;

        .action__content {
          margin-right: $margin-right-offset;
        }
      }
    }
  }

  &__txt {
    font-family: Futura Book;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.6px;
    color: $c-text-dark;
    margin-bottom: 36px;

    &:nth-child(2) {
      margin-bottom: 69px;
    }
  }

  &__visual-wrapper {
    width: 190px;
    height: 216px;
    position: relative;
    margin-left: 20px;
  }

  &__content {
    margin-bottom: 50px;

    @include mq($from: m) {
      width: $action-content-width;

      //  2. Incorporate diverse crop varieties into your menu
      &:nth-child(2) {
        .action__link-wrapper {
          width: 50%;
          margin-right: 0;
          box-sizing: border-box;

          &:nth-child(even) {
            padding-left: 10px;
          }

          &:nth-child(odd) {
            padding-right: 10px;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__subtitle {
    font-family: Futura Bold;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.6px;
    margin-bottom: 26px;
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @include mq($from: m) {
      flex-direction: row;
    }
  }

  // Add those declarations on the link wrapper in order to be able to keep the action__link inline for the multiline background to work
  &__link-wrapper {
    margin-bottom: 15px;
    margin-right: 42px;
  }

  &__link {
    font-family: Futura Book;
    font-size: 15px;
    line-height: 1;
    color: $c-yellow;

    text-decoration: none;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    transition: background-size 500ms ease-out;

    &:hover,
    &:focus {
      background-size: 0% 1px;
    }
  }

  @include mq($until: m) {
    margin-top: 0;
    padding: 0 100px 87px;

    &__container {
      display: block;
    }

    &__content {
      margin-bottom: 47px;
    }

    &__title {
      font-size: 24px;
      margin-bottom: 29px;
    }

    &__right {
      width: auto;
    }

    &__left {
      width: auto;
      margin-right: 0;
      margin-bottom: 67px;
    }

    &__txt {
      &:nth-child(2) {
        margin-bottom: 65px;
      }
    }

    &__subtitle {
      font-size: 12px;
      margin-bottom: 19px;
    }

    &__link {
      font-size: 13px;
      margin-bottom: 25px;
    }

    &__visual-wrapper {
      width: 142px;
      height: 162px;
      margin: 0 auto;
    }
  }

  @include mq($until: s) {
    padding: 0 32px 87px;
  }
}

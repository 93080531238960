.share {

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(black,.5);
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
        z-index: 99;
        opacity: 0;
        visibility: hidden;
        transition: opacity 300ms 0ms $ease-out-quad,
                    visibility 300ms 300ms $ease-out-quad;
    }

    &--show {
        &:after {
            opacity: 1;
            visibility: visible;
            transition: opacity 300ms 200ms $ease-out-quad;
        }

        .share__container {
            opacity: 1;
            visibility: visible;
            transition: opacity 300ms 200ms $ease-out-quad;
        }
    }

    &__container {
        background-color: white;
        max-width: 420px;
        position: fixed;
        right: 0;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        padding: 94px 63px 70px;
        border-radius: 10px;
        z-index: 999;
        opacity: 0;
        visibility: hidden;
        transition: opacity 300ms 0ms $ease-out-quad,
                    visibility 300ms 300ms $ease-out-quad;
    }

    &__title {
        font-family: Futura Bold;
        font-size: 28px;
        line-height: 38px;
        color: $c-text-dark;
        text-align: center;
        margin-bottom: 35px;
    }

    &__form {
        position: relative;
    }

    &__label {
        line-height: 26px;
        letter-spacing: 3px;
        color: $c-gray-blue;
        font-size: 12px;
        text-transform: uppercase;
        text-align: center;
        display: block;
        margin-bottom: 19px;
    }

    &__input {
        opacity: 0;
        position: absolute;
    }

    &__box {
        font-family: Futura Bold;
        font-size: 13px;
        line-height: 26px;
        letter-spacing: 1px;
        color: #737373;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        background-color: #F8F8F8;
        border: none;
        padding: 11px 74px 9px 16px;
        border-radius: 10px;
        width: 100%;

        &:focus {
            outline: none;
        }
    }

    &__button {
        font-family: Futura Book;
        font-size: 14px;
        line-height: 13px;
        color: $c-yellow;
        background-color: transparent;
        border: none;
        padding: 0;
        position: absolute;
        right: 20px;
        bottom: 15px;
        cursor: pointer;
    }

    &__close {
        position: absolute;
        top: 21px;
        right: 21px;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        border: 1px solid $c-border-grey;
        background-color: transparent;
        cursor: pointer;
        transition: transform 300ms $ease-out-quad;

        &:hover,
        &:focus {
            transform: rotate(90deg);
        }
    }

    &__svg {
        width: 14px;
        height: 14px;
    }

    .socials {
        justify-content: center;
        margin-bottom: 46px;

        &__link {
            background-color: #F5FAFE;
            border: none;
        }
    }

    @include mq($until: m) {

        &__container {
            height: 100vh;
            border-radius: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 94px 32px 70px;
        }
    }
}
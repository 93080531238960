.playButton {
  position: absolute;
  z-index: 1;
  display: grid;
  width: 12rem;
  height: 12rem;
  padding: 0;
  background: transparent;
  border: 1px white solid;
  border-radius: 50%;

  &__icon {
    display: block;
  }

  @include mq($until: m) {
    width: 74px;
    height: 74px;
  }
}
.section,
[class*='section--'] {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: white;

  @include mq($until: m) {
    min-height: auto;
  }

  // .article:not(:last-of-type) {
  //   margin-bottom: 10rem;
  // }

  // @include mq($from: s) {
  // flex-direction: row;
  // }

  // @include mq($from: s, $until: m) {
  // width: 100%;
  // margin-right: -$global-padding-x;
  // margin-left: -$global-padding-x;
  // }

  &[class*='--dark'] {
    color: $c-text-light;
    background: $c-deep-fir;
  }

  &[class*='--no-padding'] {
    padding: 0;
  }

  &[class*='--no-height'] {
    padding: 0;
    min-width: 100vw;

    @include mq($until: m) {
      min-height: auto;
    }
  }

  &[class*='--fullwidth'] {
    min-width: 100vw;
  }

  @include mq($from: m) {
    flex-direction: row;
    flex-shrink: 0; // Allow sections to be wider than 100vw

    &:first-child {
      //padding-right: 230px;
    }

    .article:not(:last-of-type) {
      //margin: 0 28.7rem 0 0;
    }
  }

  @include mq($until: m) {
    align-items: stretch;
    padding: 0;
    transform: none !important;
    overflow: hidden;
  }
}

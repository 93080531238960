@font-face {
  font-family: 'Futura Bold';
  src: url('../assets/fonts/futuralt-bold-webfont.woff2') format('woff2'),
    url('../assets/fonts/futuralt-bold-webfont.woff') format('woff');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Futura Book';
  src: url('../assets/fonts/futuralt-book-webfont.woff2') format('woff2'),
    url('../assets/fonts/futuralt-book-webfont.woff') format('woff');
  font-style: normal;
  font-weight: 400;
}

html {
  font-size: calc(1em * 0.625);
}

body {
  /* sass-lint:disable-block no-vendor-prefixes */
  color: $c-gray-darkest;
  font-family: #{$ff-default};
  font-size: $body-text;
  font-weight: 300;
  line-height: 1.65;

  @include mq(m) {
    font-size: $body-text-m;
  }
}

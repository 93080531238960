.signature {
    padding-left: 92px;
    background-color: $c-cream;
    display: flex;
    height: 100%;

    &__container {
        width: 345px;
        padding-top: 253px;
        margin-right: 165px;
    }

    &__title {
        font-family: Futura Bold;
        font-size: 30px;
        line-height: 42px;
        color: $c-text-dark;
        margin-bottom: 25px;
    }

    &__txt {
        font-family: Futura Book;
        font-size: 14px;
        line-height: 29px;
        letter-spacing: .6px;
        color: $c-text-dark;
        margin-bottom: 27px;
    }

    &__link {
        font-family: Futura Book;
        line-height: 13px;
        font-size: 15px;
        position: relative;
        display: inline-block; // Cannot perform translate transforms on inline elements
        color: $c-yellow;
        text-decoration: none;
        border: 1px solid $c-yellow;
        padding: 13px 24px 14px;
        border-radius: 100px;
        transition: background 300ms $ease-out-quad,
                    color 300ms $ease-out-quad;

        &:hover,
        &:focus {
            background-color: $c-yellow;
            color: white;
        }
    }

    &__visual-wrapper {
        width: 600px;
        height: 100vh;

        .visual {
            right: 0;
            left: 0;
        }
    }

    &__signature {
        position: absolute;
        bottom: 0;
        left: -30px;
        width: 510px;
        height: 216px;
        transform: rotate(-5deg);

        path {
            opacity: .1;
            fill: none;
            stroke: #000;
            stroke-width: 3;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 10;
            transition: stroke-dashoffset 550ms 1000ms $ease-out-quad;

            & + path {
                transition: stroke-dashoffset 550ms 1400ms $ease-out-quad;

                & + path {
                    transition: stroke-dashoffset 550ms 1700ms $ease-out-quad;

                    & + path {
                        transition: stroke-dashoffset 550ms 2000ms $ease-out-quad;
                    }
                }
            }
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
    }

    @include mq($until: m) {
        display: block;
        padding-left: 0;

        &__visual-wrapper {
            width: 100vw;
            height: 278px;

            .visual {
                right: 0 !important;
                left: 0 !important;
            }
        }

        &__title {
            font-size: 24px;
            margin-bottom: 21px;
            line-height: 34px;
        }

        &__txt {
            margin-bottom: 21px;
        }

        &__container {
            margin-right: 0;
            width: auto;
            padding: 80px 100px 146px;
        }

        &__fig {
            width: 231px;
            height: 95px;
            position: relative;
            bottom: 0;
            margin-top: 17px;
        }

        &__signature {
            width: 230px;
            height: 95px;
            bottom: 155px;
            left: 25px;
        }
    }

    @include mq($until: s) {
        &__container {
            padding: 80px 32px 146px;
        }
    }
}
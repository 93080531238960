.intro {
    position: absolute;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: $c-text-dark;

    &__logo-container {
        @include center-xy;
    }

    &__logo {
        max-width: 14.2rem;
        height: auto;
    }

    &__content {
        @include center-xy;
    }

    &__second {
        max-width: 146px;
        margin: 0 auto;
        height: auto;
        margin-bottom: 50px;
    }

    &__text {
        font-family: Futura Book;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: .6px;
        color: $c-text-dark;
        max-width: 62.5rem;
        text-align: center;
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;
    }

    &__skip {
        font-family: Futura Book;
        color: $c-yellow;
        line-height: 13px;
        font-size: 15px;
        background: transparent;
        border: 1px solid $c-yellow;
        border-radius: 100px;
        cursor: pointer;
        padding: 13px 30px 14px;
        display: block;
        margin: 0 auto;
        transition: background 300ms $ease-out-quad,
                    color 300ms $ease-out-quad;

        &:hover {
            background-color: $c-yellow;
            color: white;
        }
    }

    @include mq($until: m) {

        &__logo {
            max-width: 12rem;
            margin-bottom: 50px;
        }

        &__content {
            width: 90%;
        }

        &__text {
            padding-top: 60px;
        }
    }
}
.playCursor {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    width: 120px;
    height: 120px;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    margin: -60px 0 0 -60px;
    transition-duration: 200ms;
    transition-timing-function: ease-out;

    &--visible {
        transition-duration: 200ms;
        transition-timing-function: ease-out;
        opacity: 1;
        visibility: visible;
    }

    svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: white;
    }



  @include mq($until: m) {
      display: none;
  }
}
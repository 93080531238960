.post-previews {
  height: 100%;
  padding: 50px 298px 50px 218px;

  @media (min-height: 900px) {
    padding: 135px 298px 93px 218px;
  }

  &__title {
    font-family: Futura Bold;
    font-size: 38px;
    line-height: 54px;
    color: $c-text-dark;
    margin-bottom: 70px;
  }

    &__items {
      display: flex;
      flex-direction: row;
      margin: 0;
      padding: 0;
      list-style-type: none;
      margin-left: 80px;
      
      @include mq($until: m) {
        margin-bottom: 5rem;
      }
    }

    &__list-item {

        @include mq($from: s, $until: m) {
          width: 45%;
          padding: 0 $global-padding-x;

          &:first-of-type {
            padding-left: 0;
          }

          &:last-of-type {
            padding-right: 0;
          }
        }

      @include mq($from: m) {
        &:nth-of-type(even) {
          max-width: 200px;
          margin-top: 74px;
          margin-left: 168px;

          .post-preview {
            &__visual-wrapper {
              height: 132px;
            }
          }
        }
      }
    }

  @include mq($until: m) {
    padding: 0 0 100px;
    margin: 0 100px;

    &__title {
      font-size: 24px;
      margin-bottom: 21px;
    }

    &__items {
      margin-left: 0;
      justify-content: space-between;
    }

    &__list-item {
      &:nth-of-type(even) {

        .post-preview {
          &__visual-wrapper {
            height: 140px;
          }
        }
      }
    }
  }

  @include mq($until: s) {
    padding: 0 32px 100px;
    margin: 0;

    &__list-item {
      width: 45%;
      &:nth-of-type(even) {

        .post-preview {
          &__visual-wrapper {
            height: 96px;
          }
        }
      }
    }
  }
}
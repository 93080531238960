$sizes: (
  xxxl: (
    xxs: 100px,
    m: 150px,
    xl: 180px,
  ),
  xxl: (
    xxs: 80px,
    m: 120px,
    xl: 160px,
  ),
  xl: (
    xxs: 60px,
    m: 80px,
    xl: 120px,
  ),
  l: (
    xxs: 40px,
    m: 60px,
    xl: 80px,
  ),
  m: (
    xxs: 30px,
    m: 40px,
    xl: 60px,
  ),
  s: (
    xxs: 20px,
    m: 30px,
    xl: 40px,
  ),
  xs: (
    xxs: 15px,
    m: 20px,
    xl: 30px,
  ),
  xxs: (
    xxs: 15px,
    m: 15px,
    xl: 15px,
  ),
  xxxs: (
    xxs: 5px,
    m: 5px,
    xl: 5px,
  ),
);

@each $name, $value in $sizes {
  .m-#{$name} {
    @include fluid(margin-top, $value);
    @include fluid(margin-bottom, $value);
  }

  .mt-#{$name} {
    @include fluid(margin-top, $value);
  }

  .mb-#{$name} {
    @include fluid(margin-bottom, $value);
  }

  .p-#{$name} {
    @include fluid(padding-top, $value);
    @include fluid(padding-bottom, $value);
  }

  .pt-#{$name} {
    @include fluid(padding-top, $value);
  }

  .pb-#{$name} {
    @include fluid(padding-bottom, $value);
  }
}

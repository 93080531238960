.imageBackground {
  height: 100%;

  &__visual-wrapper {
   height: 100vh;
    width: 540px;
    position: relative;

    // @include mq($from: s) {
    //   width: 30rem;
    //   height: 45rem;
    //   margin-bottom: 0;
    // }

    .visual {
      right: 0;
      left: 0;
    }
  }

  @include mq($until: m) {

    &__visual-wrapper {
      height: 100%;
      width: 100%;

      .visual {
        right: 0 !important;
        left: 0 !important;
        transform: none !important;
      }
    }
  }
}
/**
* Dev, based on $env (from gulp)
*/
$env: 'development' !default;

/**
 * Colors
 * for the naming use http://chir.ag/projects/name-that-color/
 * from darker to lighter ?
 */

// Global colors
$c-black: rgb(0, 0, 0);
$c-gray-darkest: #2d2d2d;
$c-gray-darker: #5e5e5e;
$c-gray-dark: #858585;
$c-gray-medium: rgb(111, 113, 117);
$c-gray: #b2b2b2;
$c-gray-light: #d8d8d8;
$c-gray-lighter: #e6e6e6;
$c-gray-lightest: #f5f5f5;
$c-white: rgb(255, 255, 255);
$c-transparent: transparent;
$c-red: #f00;

// New colors
$c-gray-blue: #7d8093;
$c-green: #3ea439;
$c-lemon: #cce955;
$c-orange: #ff9944;
$c-darkorange: #ff7941;
$c-purple: #6e4d9a;
$c-blue: #40c7de;
$c-darkblue: #2f4d82;
$c-yellow: #e49600;
$c-cream: #f8f3ee;
$c-light-grey: #e3e3e3;
$c-extra-light-grey: #e7e7e7;
$c-linkedin: #2a67bc;
$c-facebook: #3579ea;
$c-twitter: #4aa1eb;
$c-border-grey: #a5a5a5;

// Dev colors
$c-dev-bg: #3e4b55;
$c-dev-success: #090;
$c-dev-error: #d90b0b;
$c-dev-warning: #f50;
$c-dev-info: #829deb;
$c-dev-rebecca: rebeccapurple; /* stylelint-disable-line color-named */

// Project colors
$c-deep-fir: #081301;
$c-text-light: $c-white;
$c-text-dark: #292929;
$c-surface-dark: $c-deep-fir;

/*
* Dimensions
*/
$content-width: 1680px;
$header-height: 60px;
$header-height-large: 100px;
$header-breakpoint: xl;
$global-padding-x: clamp(2rem, 2.21vw, 3.2rem);
$global-padding-y: clamp(2rem, 4.14vw, 6rem);

/*
* Typography
*/

$base-font-size: 10px !default;

$ff-default: 'futura', sans-serif;
$body-text: 1.5rem;
$body-text-m: 1.8rem;

$font-sizes: (
  h1: 3.6rem,
  h2: 2.8rem,
  h3: 2.4rem,
  h4: 1.4rem,
);

/**
* RWD
*/
$mq-responsive: true;
$mq-static-breakpoint: 'large';

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $c-cream;
    width: 100%;
    height: 100%;
    padding: 60px 400px 60px 111px;
    position: relative;

    @include mq($from: m) {
        flex-direction: row;
    }

    &__illustration {
        &:before {
            pointer-events: none;
            display: block;
            content: '';
            position: absolute;
            background-image: url(/assets/images/illustrations/passion.png);
            top: -14vh;
            right: 6vw;
            width: 30vw;
            height: 30vw;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;

            @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
                background-image: url(/assets/images/illustrations/passion@x2.png);

            }
        }
    }

    &__content {
        max-width: 75ch;
        font-family: Futura Book;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: .6px;
        color: $c-text-dark;
    }

    &__logo {
        width: 133px;
        height: 54px;
        margin-bottom: 36px;
    }

    &__txt {
        margin-bottom: 48px;
    }

    &__footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;

        @include mq($from: m) {
            width: unset;
            margin-left: 150px;
        }
    }

    &__croptrust {
        width: 162px;
        height: 73px;
    }

    &__form {
        margin-top: 50px;
        width: 322px;
        position: relative;

        &:before,
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        &:before {
            background-color: black;
        }

        &:after {
            background-color: $c-extra-light-grey;
            transform-origin: right;
            transition: transform 300ms $ease-out-quad;
        }

        &:hover,
        &:focus {
            &:after {
                transform: scaleX(0);
            }
        }
    }

    &__label {
        font-family: Futura Bold;
        font-size: 13px;
        line-height: 26px;
        letter-spacing: .6px;
        display: block;
        margin-bottom: 12px;
        color: black;
    }

    &__input {
        font-family: Futura Book;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: .6px;
        color: $c-text-dark;
        background-color: transparent;
        border: none;
        display: block;
        width: 100%;
        padding-bottom: 11px;
        padding-right: 45px;
    }

    &__submit {
        background-color: transparent;
        border: none;
        position: absolute;
        right: -10px;
        bottom: 0;
        padding: 10px;
        width: 45px;
        height: 45px;
        cursor: pointer;
    }

    &__arrow {
        width: 14px;
        height: 14px;
    }

    @include mq($until: m) {
        padding: 100px 100px 245px;

        &__illustration {
            &:before {
                top: auto;
                bottom: 30px;
                right: 0;
                left: 0;
                margin: 0 auto;
                width: 260px;
                height: 180px;
                pointer-events: none;

                @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
                    width: 260px;
                    height: 180px;
                }
            }
        }

        &__footer {
            display: block;
            background-color: $c-cream;
            position: relative;
            bottom: auto;
        }

        &__content {
            margin-bottom: 121px;
        }

        &__logo {
            width: 115px;
            height: 47px;
            margin-bottom: 31px;
        }

        &__croptrust {
            width: 124px;
            height: 56px;
            margin-bottom: 48px;
        }

        &__form {
            width: auto;
            margin-left: 0;
        }

        &__txt {
            margin-bottom: 26px;
        }
    }

    @include mq($until: s) {
        padding: 100px 32px 245px;
    }
}
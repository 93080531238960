.testimonial {
  display: flex;
  flex-direction: column;

  @include mq($from: m) {
    margin-top: 160px;
    margin-right: 230px;
    margin-left: 230px;
  }

  &__visual-wrapper {
    height: 258px;
    width: 293px;
    margin-bottom: 2.8rem;
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include mq($from: m) {
      flex-direction: row-reverse;
    }
  }

  &__content {
    font-family: Futura Book;
    position: relative;
    color: $c-text-dark;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: .6px;

    @include mq($from: s) {
      width: 283px;
      margin: 129px -30px 43px 0;
    }
  }

  &__author {
    font-family: Futura Bold;
    color: $c-text-dark;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: .6px;
    margin-bottom: 2px;
  }

  &__author-role {
    font-family: Futura Book;
    color: $c-gray-blue;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: .6px;
  }

  @include mq($from: s) {
    max-width: none;
  }

  @include mq($until: m) {
    margin-right: 0;
    margin-top: 100px;
    margin-bottom: 100px;
    position: relative;

    &__visual-wrapper {
      // height: 245px;
      // width: 264px;
      // margin-bottom: 0;
      // position: absolute !important;
      // right: 24px;
      // top: -16px;
    }

    // &__content-container {
    //   width: 340px;
    // }

    .visual {
      right: 0 !important;
      left: 0 !important;
      transform: none !important;
    }

    &__content {
      margin-top: 0;
    }
  }

  @include mq($until: s) {
    padding: 0 32px;
  }

  @include mq($until: xs) {
    &__content {
      margin-bottom: 32px;
    }

    // &__content-container {
    //   width: 221px;
    // }

    &__visual-wrapper {
      height: 145px;
      width: 164px;
    }
  }
}
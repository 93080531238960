.hero {
    position: relative;
    width: 100%;
    height: 100vh;

    &__play {
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
        z-index: 9;
        background: transparent;
        border: none;
    }

    &__logo {
        max-width: 117px;
        height: auto;
        position: absolute;
        top: 45px;
    }

    &__content {
        max-width: 60rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: auto;
        padding: 0 $global-padding-x;
        text-align: center;
        color: $c-text-light;
        margin-top: 40px;
    }
    
    &__title {
        font-family: Futura Bold;
        margin: 0 0 40px;
        font-size: clamp(4.5rem, 5.1vw, 7.4rem);
        line-height: 1.2;

        @include mq($from: m) {
            font-size: 7.4rem;
        }
    }

    &__txt {
        font-family: Futura Book;
        max-width: 425px;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: .6px;
        margin-bottom: 40px;
        mix-blend-mode: difference;
    }

    &__info {
        font-family: Futura Book;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 26px;
        letter-spacing: 3px;
        background: linear-gradient(to left, $c-lemon, $c-green);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &__responsive {
        margin-top: 55px;
        display: none;
        svg {
            width: 74px;
            height: 74px;
            fill: white;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        color: currentColor;
        background: transparent;
        border-width: 0;
        position: absolute;
        left: 0;
        width: 120px;
        height: 120px;

        .label {
            font-size: 1.3rem;
            font-weight: 700;
            text-transform: uppercase;
        }

        .icon {
            position: relative;
            margin-left: -0.5rem;
        }

        svg {
            display: block;
        }

        .play {
            @include center-xy;
        }
    }

    &__background {
        @include get-all-space();

        z-index: -1;
        overflow: hidden;
        user-select: none;
        background-color: $c-deep-fir;
        clip-path: inset(0% 0% 0% 100%);

        &::after {
            @include get-all-space();

            content: '';
            pointer-events: none;
            background: linear-gradient(to right, rgba(#0E0E0E,.2), rgba(#0E0E0E,.1)),
                        linear-gradient(to top, rgba(#081301,.6 ), rgba(#081301,0.4));
        }

        &::before {
            content: '';
            background:linear-gradient(to bottom, rgba(black,0), rgba(black,.2));
            height: 223px;
            width: 100%;
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 9;
        }

        // ℹ️ Note: the additional wrapper avoid clashes between GSAP controlled scale
        // and the translateX controlled with CSS variables used for the parallax effect
    }

    &__visual-container {
        @include get-all-space();
    }

    &__visual {
        @include parallax-translate-x(5%);
        position: absolute;
        top: 0;
        right: -5%;
        bottom: 0;
        left: -5%;
        width: 110%;
        max-width: none;
        height: 100%;
        object-fit: cover;
        object-position: 100% 100%;
        
        // Make sure that video controls are hidden
        &::-webkit-media-controls {
          display: none;
        }
    }

    @include mq($until: m) {
        z-index: 9;
        height: 100vh;

        &__responsive {
            display: block;
        }

        &__content {
            margin-top: 0;
        }

        &__title {
            font-size: 44px;
        }

        &__visual {
            width: 100%;
            right: 0;
            left: 0;
            transform: none;
        }

        &__close {
            top: 20px;
            right: 20px;
        }
    }
}
.fullscreen-media {
  @include get-all-space();

  &__play {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 9;
    background: transparent;
    border: none;
  }

  &__responsive {
    margin-top: 55px;
    display: none;
    svg {
        width: 74px;
        height: 74px;
        fill: white;
        position: absolute;
        z-index: 2;
        right: 0;
        left: 0;
        top: 148px;
        margin: 0 auto;
    }
  }

  &__content {
    position: absolute;
    bottom: 59px;
    left: 114px;
    max-width: 864px;
  }

  &__pre-pretitle {
    font-family: Futura Book;
    color: white;
    margin: 0 0 28px;
    font-size: 12px;
    font-weight: 200;
    line-height: 26px;
    letter-spacing: 3px;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    transform: translateX(calc(-100px * var(--parallax)));
  }

  &__title {
    color: white;
    margin: 0;
    font-size: 56px;
    line-height: 68px;
    font-weight: 700;
    margin-bottom: 36px;
    position: relative;
    z-index: 1;
    transform: translateX(calc(-200px * var(--parallax)));
  }

  &__more {
    font-family: Futura Book;
    line-height: 13px;
    font-size: 15px;
    position: relative;
    display: inline-block; // Cannot perform translate transforms on inline elements
    color: white;
    text-decoration: none;
    border: 1px solid white;
    padding: 13px 24px 14px;
    border-radius: 100px;
    transition: background 300ms $ease-out-quad, color 300ms $ease-out-quad;

    &:hover,
    &:focus {
      background-color: white;
      color: black;
    }
  }

  &__background {
    @include get-all-space;
    overflow: hidden;
  }

  // ℹ️ Note: the additional wrapper avoid clashes between GSAP controlled scale
  // and the translateX controlled with CSS variables used for the parallax effect
  &__visual-container {
    @include get-all-space();
    @include parallax-translate-x(10%);
  }

  &__visual {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__shadow-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      transparentize(#000, 0.2),
      transparentize(#000, 1)
    );
  }

  &__container {
    opacity: 1;
    z-index: 999;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    visibility: visible;
    transition: opacity 300ms 300ms $ease-out-quad,
      visibility 300ms 0ms $ease-out-quad;
  }

  &__video {
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: block;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
  }

  &__close {
    position: absolute;
    top: 51px;
    right: 60px;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    border: 1px solid white;
    background-color: transparent;
    cursor: pointer;
    z-index: 999;
    transition: transform 300ms $ease-out-quad;

    &:hover,
    &:focus {
      transform: rotate(90deg);
    }
  }

  &__svg {
    width: 14px;
    height: 14px;
    fill: white;
  }

  .video__hide {
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms 0ms $ease-out-quad,
      visibility 0ms 300ms $ease-out-quad;
  }

  &__controls {
    position: absolute;
    bottom: 54px;
    right: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 64px 0 54px;
  }

  &__timeline {
    height: 3px;
    width: calc(100% - 220px);
    background-color: rgba(white, 0.5);
    position: absolute;
    left: 100px;
    top: 10px;
  }

  &__progress {
    height: 3px;
    width: 50%;
    background: linear-gradient(
      to left,
      $c-orange,
      $c-darkorange,
      $c-purple,
      $c-darkblue,
      $c-blue,
      $c-green,
      $c-lemon
    );
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  &__duration {
    position: absolute;
    right: 64px;
  }

  &__numbers {
    font-family: futura bold;
    font-size: 12px;
    line-height: 26px;
    letter-spacing: 2px;
  }

  &__btnplay,
  &__btnstop {
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    left: 54px;
    padding: 5px;
    transition: opacity 300ms $ease-out-quad;

    &:after {
      display: block;
      content: '';
    }

    &:disabled {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__btnplay {
    &:after {
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 10px solid white;
    }
  }

  &__btnstop {
    &:after {
      width: 9px;
      height: 11px;
      top: calc(50% - 6px);
      left: calc(50% - 5px);
      border-right: 3px solid white;
      border-left: 3px solid white;
    }
  }

  @include mq($until: m) {
    position: relative;
    width: 100vw;
    height: 496px;

    &__background {
      width: 100vw;
    }

    &__responsive {
      display: block;
    }

    &__content {
      position: absolute;
      bottom: 30px;
      left: auto;
      padding: 0 100px;
    }

    &__pre-pretitle {
      font-size: 11px;
      margin-bottom: 10px;
    }

    &__title {
      font-size: 24px;
      margin-bottom: 27px;
      line-height: 33px;
    }

    &__visual-container {
      transform: none;
    }
  }

  @include mq($until: s) {
    &__content {
      padding: 0 32px;
      max-width: 360px;
    }
  }
}

.scrollCta {
    opacity: 0;
    visibility: hidden;
    position: relative;
    
    &--show {
        opacity: 1;
        visibility: visible;
    }

    &__button{
        position: fixed;
        right: clamp(2rem, 4.14vw, 6rem);
        bottom: clamp(2rem, 2.21vw, 3.2rem);
        padding: 0;
        background: transparent;
        border-width: 0;
        background-attachment:fixed;
        mix-blend-mode: exclusion;
        fill: white;
        z-index: 99;
        cursor: pointer;
    }

    @include mq($until: m) {
        right: 40px;
        //bottom: 0;
    }
}
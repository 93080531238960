.link {
    font-family: Futura Book;
    line-height: 13px;
    font-size: 15px;
    position: relative;
    display: inline-block; // Cannot perform translate transforms on inline elements
    color: $c-yellow;
    text-decoration: none;
    border: 1px solid $c-yellow;
    padding: 13px 24px 14px;
    border-radius: 100px;
    transition: background 300ms $ease-out-quad,
                color 300ms $ease-out-quad;

    &:hover,
    &:focus {
        background-color: $c-yellow;
        color: white;
    }
}

#whitelink {
    color: white;
    border: 1px solid white;
    position: relative;
    z-index: 999;
    cursor: pointer !important;

    &:hover,
    &:focus {
        background-color: white;
        color: black;
    }
}